// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

    $('.js-replace-img').each(function() {

        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
}

jQuery(function() {
    if ($(".js-replace-img")) {
        replaceSrc();
    }
});

(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 100;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// SUBNAV NAVIGATION
// $("ul li.menu-item-has-children > a").click(function (e) {
//     e.preventDefault();
//     var LiParent =$(this).parent().attr('class');
//     if(! LiParent.includes("openSub-menu") ){
//         $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
//     }
//
//     $(this).parent().toggleClass("openSub-menu");
//
// });

$(document).ready(function(){
    // Cacher tous les sous-menus au chargement de la page
    $('.sub-menu').hide();

    // Au clic sur un élément du menu principal
    $('#menu-primary-navigation .menu-item-has-children > a').click(function(e){
        e.preventDefault(); // Empêcher le lien par défaut de s'exécuter

        // Fermer les autres sous-menus ouverts et supprimer la classe 'openSub-menu'
        var parentLi = $(this).parent('.menu-item-has-children');
        $('.menu-item-has-children').not(parentLi.parents('.menu-item-has-children')).removeClass('openSub-menu').find('.sub-menu').hide();

        // Afficher ou masquer le sous-menu correspondant
        var subMenu = $(this).siblings('.sub-menu');
        subMenu.toggle();

        // Ajouter ou supprimer la classe 'openSub-menu' à l'élément parent
        if (subMenu.is(':visible')) {
            parentLi.addClass('openSub-menu');
        } else {
            parentLi.removeClass('openSub-menu');
        }
    });

    // Fermer le sous-menu lors d'un clic en dehors du menu
    $(document).click(function(event) {
        if(!$(event.target).closest('#menu-primary-navigation').length) {
            $('.menu-item-has-children').removeClass('openSub-menu');
            $('.sub-menu').hide();
        }
    });
});

// -- CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $("ul.sub-menu").removeClass("openSub-menu");
    }
});

// ANCRE
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 1200, 'swing', function () {
        window.location.hash = target;
    });
});

// COUT NUMBER ANIMATION
// inViewport jQuery plugin
// http://stackoverflow.com/a/26831113/383904
// Based on Fiddle: https://jsfiddle.net/2v3mq3nh/4/
// From this Answer: http://stackoverflow.com/a/36980280
$(function($, win) {
  $.fn.inViewport = function(cb) {
    return this.each(function(i,el){
      function visPx(){
        var H = $(this).height(),
            r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
        return cb.call(el, Math.max(0, t>0? H-t : (b<H?b:H)));
      } visPx();
      $(win).on("resize scroll", visPx);
    });
  };
}(jQuery, window));

jQuery(function($) { // DOM ready and $ in scope

  $(".count").inViewport(function(px) { // Make use of the `px` argument!!!
    // if element entered V.port ( px>0 ) and
    // if prop initNumAnim flag is not yet set
    //  = Animate numbers
    if(px>0 && !this.initNumAnim) {
      this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
      $(this).prop('Counter',0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        step: function (now) {
          $(this).text(parseFloat(now.toFixed(0)));
        }
      });
    }
  });

});


$(document).ready(function(){
  $('.slideshow .slider-wrapper #slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });
});

$(document).ready(function(){
  $('.services-wrapper .content').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
  }
},]
  });
});

$(document).ready(function(){
  $('.home-news > .content').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
  {
    breakpoint: 1025,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
  }
},]

  });
});

$(document).ready(function(){
  $('.active-slider .wrapper-block').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
  }
},]
  });
});

$(document).ready(function(){
  $('.team-slider > .content').slick({
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
  }
},]
  });
});

$(document).ready(function(){
  var totalSlidesAgenda = $('.repeater-logo-wrapper > .content article').length;
  $('.repeater-logo-wrapper > .content').slick({
    slidesToShow: totalSlidesAgenda >= 6 ? 6 : totalSlidesAgenda,
    slidesToScroll: 6,
    responsive: [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: totalSlidesAgenda >= 2 ? 2 : totalSlidesAgenda,
    slidesToScroll: 2,
  }
},]
  });
});
